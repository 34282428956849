.bg {
    background-color: #001f3f
}
.norms {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 16px;
    /* line-height: 5pt; */
    /* font-weight: 100;				 */
}
.TitleA {
    color: #175778;
    /* font-family: "Roboto"; */
    font-size: 14pt;
    /* line-height: 5pt; */
    /* font-weight: 100;				 */
}

.TitleC {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 28px;
    color: #636161;
    /* line-height: 1.5px; */
    /* line-height: 8pt; */
    /* font-weight: 100;				 */
}

.TitleF {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 40px;
    color: #636161;
}
.TitleCc {
    color: #175778;
    font-size: 24px;
    line-height: 1px;

}
.TitleCb {
    color: #175778;
    font-size: 14px;
    line-height: 1px;

}
.TitleD {
    color: #175778;
    /* font-family: "Roboto"; */
    font-size: 16pt;
    /* line-height: 5pt; */
    /* font-weight: 100;				 */
}
.TitleDB {
    color: #636161;
    /* font-family: "Roboto"; */
    /* text-decoration: None; */
    font-size: 17px;
    line-height: .9px;
    font-weight: 500;
}
.TitleDBB {
    color: #636161;
    /* font-family: "Roboto"; */
    font-size: 16px;
    line-height: 1px;
    font-weight: 500;
}
.ACT {
    color: #003765;
}

.ST {
    color: #B1394A;
}
.specTable td {
    border-spacing: 1px;
  border-collapse: collapse;
  padding-left: 0px;
  padding-right: 0px;
  line-height: 1.1;
  font-size: 12px;
  border-style: solid;
  border-color: #CDCDCD;
  border-width: 1px;
  /* padding: 15px */
  }
.areports_table table {
    height: 70%;
    width: 80%;
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
    /* border: 1px solid black; */
}
.reports_table table {
    height: 50%;
    /* width: 80%; */
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
    /* border: 1px solid black; */    
}

.reports_tableX table {
    height: 50%;
    /* width: 80%; */
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
    /* border: 1px solid black; */    
}

.reports_tableX tr {
    padding: 0px;
}
.reports_tableX td {
    border-right: 1px solid #CED4DA;
    border-left: 1px solid #CED4DA;
}
/* .special th:nth-child(3)  {
    background-color: #555557;
    
 }

.special th:nth-child(4)  {
    background-color: #555557;
    
 }
.special th:nth-child(5)  {
    background-color: #555557;
    
 } */

.special th:nth-child(-n+3)  {
    background-color: #555557;
    
 }
 .specialX th:nth-child(3)  {
    background-color: #A3049F;
 }

 .specialX th:nth-child(-n+2)  {
    background-color: #555557;

}
.specialXSC table {  background-color:#FFFFFF ;}

.specialXSC tr:first-child  {
    font-weight: 900;
 }
.specialXSC th:first-child  {
    background-color: #555557;
 }

 .specialXSC th:nth-child(2)  {
    background-color: #FFC000;
}
.specialXSC th:nth-child(3)  {
    background-color: #FF0000;
}
.specialXSC th:nth-child(4)  {
    background-color: #003EC0;
}
.specialXSC th:nth-child(5)  {
    background-color: #945200;
}
.specialXSC th:nth-child(7)  {
    background-color: #945200;
}
.specialXSC th:nth-child(6)  {
    background-color: #7001A0;
}
.specialXSC th:nth-child(8)  {
    background-color: #7001A0;
}
/* .specialXSC thead th  {
   position: sticky;
   top: 0;
   z-index: 20;
 } */
 .specialXSC thead {
    position: sticky;
    top: 0;
    z-index: 40;
  }
  .specialXSCM table {  background-color:#FFFFFF ;}

  .specialXSCM tr:first-child  {
      font-weight: 900;
   }
  .specialXSCM th:first-child  {
      background-color: #555557;
   }
  
   .specialXSCM th:nth-child(2)  {
      background-color: #375623;
  }
  
   .specialXSCM th:nth-child(3)  {
      background-color: #70AD47;
  }
  
  
   .specialXSCM th:nth-child(4)  {
      background-color: #FFC000;
  }
  .specialXSCM th:nth-child(5)  {
      background-color: #FF0000;
  }
  .specialXSCM th:nth-child(6)  {
      background-color: #003EC0;
  }
  .specialXSCM th:nth-child(7)  {
      background-color: #945200;
  }
  .specialXSCM th:nth-child(9)  {
      background-color: #945200;
  }
  .specialXSCM th:nth-child(8)  {
      background-color: #7001A0;
  }
  .specialXSCM th:nth-child(10)  {
      background-color: #7001A0;
  }

   .specialXSCM thead {
      position: sticky;
      top: 0;
      z-index: 40;
    }

    .specialBT thead {
      position: sticky;
      top: 0;
      z-index: 40;
    }

  .specialBT tr:first-child  {
    font-weight: 900;
 }
.specialBT th
 {min-width: 60px;}

 /* .specialXSC thead  {
    position: sticky;
    top: 0;
    z-index: 20;
  } */
 /* .specialXSC tbody tr:first-child  {
    position: sticky;
    top: 0;
    z-index: 20;
  } */
 /* .specialXSC thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  } */
  /* .specialXSC tbody th {
    position: sticky;
    left: 0;
    z-index: 1;
  } */

  .specialXSCD2 table {  background-color:#FFFFFF ;}
  .specialXSCD2 td {
    padding: 4px 5px;
    z-index: 0;
  }
  
  .specialXSCD2 tbody  {
    position: relative;
    z-index: 0;
  }
  .specialXSCD2 tbody tr {
    background-color:#FFFFFF ;
    z-index: 0;
  }

  .specialXSCD2 thead th {
    padding: 0px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    max-width: 80px;
    /* background: white; */
  }
  .specialXSCD2 tr th:first-child {
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
  }

  .specialXSCD2 thead th:first-child {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
    width: 25vw;
    background-color:  #000000;
  }

  .specialXSCD2 tr td:first-child {
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
  }
  
  .specialXSCD2 tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  .specialXSCD2 th:nth-child(odd) {
    background-color: #BCBD22;
    padding: 0px;
    /* border: 1px solid #CED4DA; */
}
    .specialXSCD2 th:nth-child(even) {
        background-color: #226277;
        padding: 0px;
        /* border: 1px solid #CED4DA; */
    }
    /* .specialXSCD2 tbody tr:nth-child(even) {background-color: #FFFFFF;} */
    .specialXSCD2 tbody tr:first-child {background-color: #D5ECFF;}
    .specialXSCD2 tbody tr:nth-child(2) {background-color: #D5ECFF;} 
    /* #E2F1F7 */
    .specialXSCD2 tbody tr:nth-child(3) {background-color: #FFF7EA;}

    .specialXSCD2 tbody tr:nth-child(4) {background-color: #F4F4F4;}
    .specialXSCD2 tbody tr:nth-child(5) {background-color: #F2F0FF;}
    .specialXSCD2 tbody tr:nth-child(6) {background-color: #FFF7EA;}
    .specialXSCD2 tbody tr:nth-child(7) {background-color: #FFF7EA;}
    .specialXSCD2 tbody tr:nth-child(8) {background-color: #FFF7EA;}
    .specialXSCD2 tbody tr:nth-child(9) {background-color: #FFF7EA;}
    .specialXSCD2 tbody tr:nth-child(10) {background-color: #FFF7EA;}
    .specialXSCD2 tbody tr:nth-child(11) {background-color: #FFF7EA;}
    /* .specialXSCD2 tbody tr:nth-child(12) {background-color: #F4F4F4;}
    .specialXSCD2 tbody tr:nth-child(13) {background-color: #F4F4F4;}
    .specialXSCD2 tbody tr:nth-child(14) {background-color: #F4F4F4;}
    .specialXSCD2 tbody tr:nth-child(15) {background-color: #F4F4F4;}
    .specialXSCD2 tbody tr:nth-child(16) {background-color: #F4F4F4;} */

    .specialXSCD2 tbody tr:nth-last-child(8) {background-color: #F4F4F4;}
    .specialXSCD2 tbody tr:nth-last-child(7) {background-color: #F4F4F4;}
    .specialXSCD2 tbody tr:nth-last-child(6) {background-color: #F4F4F4;}
    .specialXSCD2 tbody tr:nth-last-child(5) {background-color: #F4F4F4;}
    .specialXSCD2 tbody tr:nth-last-child(4) {background-color: #F4F4F4;}


    /* .specialXSCD2 tbody tr:nth-child(17) {background-color: #E5ECF6;} */
    .specialXSCD2 tbody tr:nth-last-child(3) {background-color: #E5ECF6;}

    /* .specialXSCD2 tbody tr:nth-child(18) {background-color: #F4D3F5;} */
    /* .specialXSCD2 tbody tr:nth-child(19) {background-color: #F4D3F5;} */
    .specialXSCD2 tbody tr:nth-last-child(2) {background-color: #F4D3F5;}
    .specialXSCD2 tbody tr:nth-last-child(1) {background-color: #F4D3F5;}


    .specialXSCD2F tbody tr:nth-child(4) {background-color: #F4D3F5; } 
    .specialXSCD2F tbody tr:nth-child(5) {background-color: #F4D3F5;} 
    .specialXSCD2F tbody tr:nth-child(6) {background-color: #FDCF16;} 
    .specialXSCD2F tbody tr:nth-child(7) {background-color: #B4CF16;} 

    .specialXSCD2F tbody tr:nth-child(8) {background-color: #F4D3F5; } 
    .specialXSCD2F tbody tr:nth-child(9) {background-color: #FDC000;} 

    .specialXSCD2F tbody tr:nth-child(10) {background-color: #FDCF16;} 
    .specialXSCD2F tbody tr:nth-child(11) {background-color: #B4CF16;} 
     
          
    /* .specialXSCD2 tbody tr:nth-child(18) {background-color: #FDCF16;} */
/* F4D3F5 */

    .specialSticky table {  background-color:#FFFFFF ;}
    .specialSticky td {
      padding: 4px 5px;
      z-index: 0;
    }
    
    .specialSticky tbody  {
      position: relative;
      z-index: 0;
    }
    .specialSticky tbody tr {
      background-color:#FFFFFF ;
      z-index: 0;
    }
  
    .specialSticky thead th {
      padding: 0px;
      position: sticky;
      top: 0;
      z-index: 1;
      width: 25vw;
      /* background: white; */
    }
    .specialSticky tr td:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
    }
  
    .specialSticky thead tr:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 40;
      width: 25vw;
      /* background-color:  #000000; */
    }
   
    .specialSticky tr th:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
    }
    
    .specialSticky th {
min-width: 40px;
    }

    .specialSticky tbody td:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  
    .specialStickyH thead tr:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 40;
      width: 100vw;
    }

    .specialStickyFX table {  background-color:#FFFFFF ;}

    .specialStickyFX td {
      padding: 4px 5px;
      z-index: 0;
    }
    
    .specialStickyFX tbody  {
      position: relative;
      z-index: 0;
    }
    .specialStickyFX tbody tr {
      background-color:#FFFFFF ;
      z-index: 0;
    }
  
    .specialStickyFX thead th {
      padding: 0px;
      position: sticky;
      top: 0;
      z-index: 1;
      width: 25vw;
    }
    .specialStickyFX tr td:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
      width: 200px;
    }
  
    .specialStickyFX thead tr:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 40;
      width: 50vw;
      /* background-color:  #000000; */
    }
  
    .specialStickyFX tr th:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
       width: 200px;
    }
    
    .specialStickyFX tbody td:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
       width: 200px;
      
    }

    .specialStickyFX2 table {  background-color:#FFFFFF ;}

    .specialStickyFX2 td {
      padding: 4px 5px;
      z-index: 0;
    }
    
    .specialStickyFX2 tbody  {
      position: relative;
      z-index: 0;
    }
    .specialStickyFX2 tbody tr {
      background-color:#FFFFFF ;
      z-index: 0;
    }
  
    .specialStickyFX2 thead th {
      padding: 0px;
      position: sticky;
      top: 0;
      z-index: 1;
      width: 25vw;
      background-color: #570054;
    }
    .specialStickyFX2 tr td:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
      width: 200px;
    }
  
    .specialStickyFX2 thead tr:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 40;
      width: 50vw;
      /* background-color:  #000000; */
    }
  
    .specialStickyFX2 tr th:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: #000000;
      width: 250px;
      max-width: 250px;
      min-width: 250px;
      border-right: 1px solid black;
    }
    
    .specialStickyFX2 tbody td:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
       width: 250px;
       border-right: 1px solid black;
    }


    /* .specialXSCD2 tbody tr:nth-child(12) {background-color: #F4F4F4;} */


    /* E7E1F9 */
    /* .specialXSCD2 tbody tr:nth-last-child(6) {background-color: #F4F4F4;} */
    /* .specialXSCD2 tbody tr:nth-last-child(5) {background-color: #F4F4F4;} */

    /* border-top: 1px solid #CED4DA; */
    /* border-bottom: 1px solid #CED4DA; */
    
    /* border: 0px solid #B9B9B9; */
/* 945200 */
/* .anotherSpecialX th:nth-child(-n+10) { */

.anotherSpecialX th:nth-child(n+12) {
    background-color:  #000000;
}

.yetAnotherSpecialX th:nth-child(n+8) {
    background-color:  #000000;
}
.lastChild tr:last-child {
    font-weight: 800;
    color: #000000;
    background-color: #D6D6D6;
  }
  
  .lastChildCol td:last-child {
    font-weight: 800;
    color: #000000;
    background-color: #D6D6D6;
  }
  /* td:first-child */
  .firstChildColX td:first-child {
    /* color: #FFFFFF ; */
    text-align: left;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #17BECF;
    border-left: 1px solid #17BECF;
    border-top: 1px solid #17BECF;
    border-bottom: 1px solid #17BECF;
    color: #17BECF;
}
.firstChildCol td:first-child {
    /* color: #FFFFFF ; */
    text-align: left;
    font-weight: 1000;
    padding-left: 10px;
    padding-right: 10px;
   /* border-bottom: 1px solid #FFFFFF;
    border-left: 1px solid #FFFFFF;
    border-right: 2px solid #FFFFFF;
     background-color: #17BECF;
    font-size: 18px; */
}

.twoArm_tableBX9 tr:nth-child(even) {background-color: #FFFFFF;}
.twoArm_tableBX9 tr:nth-child(odd) {background-color: #F4F4F4;}
.twoArm_tableBX9 td {text-align:center;}
/* nth-child(n+6) */
/* .twoArm_tableBX9 td:first-child {background-color: #D6D6D6;} */

/* ,  nth-child(even) tr:nth-child(even)  borderRightWidth: "1px", borderRightColor:"#aaaaaa", borderRightStyle:'solid'  */
.reports_tableX tr:first-child td {
    border-top: 0;
  }
  .reports_tableX tr td:first-child {
    border-left: 0;
  }
  .reports_tableX tr:last-child td {
    border-bottom: 0;
  }
  .reports_tableX tr td:last-child {
    border-right: 0;
  }
  .reports_tableX tr:last-child {
    border-bottom: 1px solid #CED4DA;
  }
  /* .reports_tableY tr:first-child td {
    border-top: 0;
  }
  .reports_tableY tr td:first-child {
    border-left: 0;  f9f8fa
  } */
  .reports_tableY td,th {
    border: 1px solid #B9B9B9;
  }
  /* .twoArm_table tr:nth-child(even) {background-color: #FBFAFE;} */
  .reports_tableXsp td:first-child  {
    border-top: 0;
    color: #FFFFFF;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
  }
  .reports_tableXsp th:first-child  {
    border-top: 0;
    color: #FFFFFF;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
  }
  .reports_tableXsp tr td:first-child {
    border-left: 0;
    border: 1px solid #FFFFFF;
  }
  .reports_tableXsp tr:last-child td {
    border-bottom: 0;
  }
  .reports_tableXsp tr :last-child {
    border-right: 0;
  }
  .reports_tableXsp td{
    border-right: 1px solid #B9B9B9;
  }
  .reports_tableXsp th{
    border-right: 1px solid #B9B9B9;
  }
  /* .reports_tableXsp tr:last-child {
    border-bottom: 1px solid #CED4DA;
  } */
.reports_table004 table {
    height: 50%;
    /* width: 80%; */
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
    /* border: 1px solid black; */
}

.reports_table004 tr {
    padding: 0px;

}
.areports_table tr {
    padding: 0px;
    /* font-family: "Roboto"; */
    font-size: 12px;
}

.reports_table td {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 10px;
    /* line-height: 5pt; */
    /* height: 1px; */
    /* height: 2px; */
    font-weight: 400;
}
.reports_table004 td {
    /* color: #175778; */
    /* font-family: "Roboto"; */
    font-size: 10px;
    /* line-height: 1.2px; */
    /* height: 1px; */
    /* height: 2px; */
    font-weight: 400;
}
.reports_table004 th {
    /* font-family: "Roboto"; */
    font-size: 11px;
    font-weight: 500;
    /* height: 1px; */
}
.reports_table th {
    /* font-family: "Roboto"; */
    font-size: 11px;
    font-weight: 500;
    /* height: 1px; */
}
.reports_tableXSCA table {
    height: 50%;
    /* width: 80%; */
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
    /* border: 1px solid black; */    
}

.reports_tableXSCA tr {
    padding: 0px;
}
.reports_tableXSCA td {
    border-right: 1px solid #CED4DA;
    border-left: 1px solid #CED4DA;
}

.reports_tableXSCA th:first-child{
    background-color: 1px solid #000000;
    border-left: 1px solid #CED4DA;
}
.reports_tableXSCA th:nth-child(2){
    background-color: 1px solid #FF0000;
    border-left: 1px solid #CED4DA;
}
.article {
    float: left;
    padding-left: 20px;
    width: 90%;
    margin-left: 10%;
}
.articleVS {
    float: left;
    padding-left: 120px;
    /* margin-left: 200px; */
    /* width: 90%; */
    /* margin-left: 10%; */
}
.articleVSB {
  float: left;
  padding-left: 120px;
  width: 90%;
}

.articleRight {
    float: right;
    padding-right: 10px;
    width: 20%;
    /* margin-left: 10%; */
}

.articleB {
    float: left;
    padding: 15px;
    width: 90%;
    margin-left: 10%;
}
.sidenav {
    float: left;
    height: 100%;
    width: 35%;
    padding-right: 25px;
    padding-left: 2px;
    position: absolute;
}
.sidenavB {
    float: left;
    height: 100%;
    width: 10%;
    padding: 20px;
    position: absolute;
}
.sidenavRight {
    float: right;
    height: 100%;
    width: 35%;
    padding-right: 8px;
    padding-left: 22px;
    position: absolute;
}


.dropdownA {
    position: relative;
    display: inline-block;
}

.twoArm_table th {background-color: #FFFFFF;}
/* .twoArm_table tr:nth-child(4n) {background-color: #941751;} */
.twoArm_tableB th {background-color: #FFFFFF;}


.twoArm_table tr:nth-child(even) {background-color: #FBFAFE;}
.twoArm_table tr:nth-child(odd) {background-color: #F6F8FE;}
/* F9F8FA */
.twoArm_tableB tr:nth-child(even) {background-color: #FFFFFF;}
.twoArm_tableB tr:nth-child(odd) {background-color: #F9F8FA;}



.twoArm_tableNoCol tr:nth-child(even) td:nth-child(even) {
  background-color: #8C8785;
  max-width: 70px;
}
.twoArm_tableNoCol tr:nth-child(even) td:nth-child(odd) {
  background-color: #000000;

}
.twoArm_tableNoCol tr:nth-child(odd) td:nth-child(odd) {
  background-color: #8C8785;
  
}
.twoArm_tableNoCol tr:nth-child(odd) td:nth-child(even) {
  background-color: #000000;
  max-width: 70px;
}
.twoArm_tableNoCol  td:first-child {
  font-size: 13px;
}
.twoArm_tableNoCol  td:nth-child(2) {
  font-size: 15px;
}

.twoArm_tableNoCol2 tr:nth-child(even) td:nth-child(even) {
  background-color: #A1A1A1;
  max-width: 70px;
}
.twoArm_tableNoCol2 tr:nth-child(even) td:nth-child(odd) {
  background-color: #8C8785;

}
.twoArm_tableNoCol2 tr:nth-child(odd) td:nth-child(odd) {
  background-color: #A1A1A1;
  
}
.twoArm_tableNoCol2 tr:nth-child(odd) td:nth-child(even) {
  background-color: #8C8785;
  max-width: 70px;
}
.twoArm_tableNoCol2  td:first-child {
  font-size: 13px;
}
.twoArm_tableNoCol2  td:nth-child(2) {
  font-size: 15px;
}

.twoArm_tableBX tr:nth-child(even) {background-color: #FFFFFF;}
.twoArm_tableBX tr:nth-child(odd) {background-color: #F4F4F4; border: "1px solid black";}
.twoArm_tableBX tr:last-child {background-color: #D6D6D6;}


.twoArm_tableBXtoo tr:nth-child(even) {background-color: #FFFFFF;}
.twoArm_tableBXtoo tr:nth-child(odd) {background-color: #F4F4F4;}
.twoArm_tableBXtoo tr:nth-last-child(-n+2) {background-color: #D6D6D6;}

/* .twoArm_tableBXtwo tr:nth-last-child(n-2){background-color: #D6D6D6;}   nth-child(-n+2)*/

.lastChildTwo tr:nth-last-child(-n+2) {
    font-weight: 800;
    color: #000000;
    background-color: #D6D6D6;
  }



  /* .blankTwo td:  {
    color: #FFFFFF;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
} */
  /* tr.blankTwo td:last-child  {
    color: #FFFFFF;
    background-color: #FFFFFF;
    border: #FFFFFF;
  } */
  /* td:last-child of class tr.blankTwo{} */

/* #F4F4F4 #F9F8FA */

.threeArm_table th {background-color: #FFFFFF;}
.threeArm_table tr:nth-of-type(3n+2) {background-color: #FBFAFE;}
.threeArm_table tr:nth-of-type(3n+1) {background-color: #F6F8FE;}
.threeArm_table tr:nth-of-type(3n) {background-color: #FFFFFF;}

.consort_table table{
    background-color: "white";
    width: 20%;
    padding-left: 70px;
    margin-left:50;
    border: "1px solid black";
    height: 50%;
    border-spacing: 1px;
    border-collapse: collapse;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1px;
}
.consort_table th{
    background-color: "#76A0C0";
    color: "white";
    border: "1px solid black";
    font-weight: 500;
    font-size: 11px;
    border-collapse: collapse;
}
.consort_table td{
    background-color: "white";
    border: "1px solid black";
    font-size: 10px;
    font-weight: 400;
}
.consort_table thB{
    background-color: "#white";
    color: "white";
    border: "1px solid black";
    font-weight: 500;
    font-size: 11px;
}
.fubutton {
    display: inline-block;
    color: white;
    text-align: left;
    /* padding: 14px 16px; */
    text-decoration-line: none;
}
li a, .dropbtn {
    display: inline-block;
    color: white;
    text-align: left;
    /* padding: 14px 16px; */
    text-decoration-line: none;
}

li a:hover, .dropdown:hover .dropbtn {
    background-color:#18BC9C;
    text-decoration-line: none;
}

li.dropdown {
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    /* background-color: #f9f9f9; */
    min-width: 260px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 1;
    color: #636161;
    /* font-family: "Roboto"; */
    font-size: 14px;
    line-height: 1px;
    font-weight: 500;
}

.dropdown-content a {
    color: black;
    /* padding: 12px 16px; */
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {
    display: block;}


consortBB {
    background-image : "consort_diagram004B.png";
      }

.square {
width: 24px;
height: 24px;
background-color: #9C4341

}

/* @page {  
      size: 8.5in 11in;
      margin: 1in;
      max-height: 10in;
      max-width: 8.5in; 
    } */
/*@media print {    .no-print, .no-print *
    {
        display: none !important;
    }
}*/

/* .col_table td:nth-child(even) {border-right: #AAAAAA;} */


  
 
.blankTwo tr {border: "1px solid black"; padding-bottom: 0px;border-left: 1px solid #0092D0;border-right: 1px solid #0092D0;}
  .blankTwo td:nth-child(even) {background-color: #FFFFFF; border: "1px solid black"; padding-bottom:2px; padding-right:2px;padding-left:4px; vertical-align: bottom;line-height: 1.1;}
  .blankTwo td:nth-child(odd) {background-color: #F4F4F4; border: "1px solid black";padding-bottom:2px; padding-right:2px;padding-left:4px; vertical-align: bottom;line-height: 1.1; }
  .blankTwo td:first-child {color: #F4F4F4;}
  .blankTwo tr:nth-of-type(7n+1) td  {
    color: #FFFFFF;
    font-size:25px;
    background-color: #0092D0;
    border: 1px solid #0092D0;
    border-top: 1px solid #F4F4F4;
    padding-top: 10px;
    padding-bottom:0px;
    padding-left:6px;
    padding-right:6px;
  }
  .blankTwo tr:nth-of-type(7n+1) {border-left: 2px solid #0092D0;border-right: 2px solid #0092D0;}
  .blankTwo tr:nth-of-type(7n) {border-bottom: 1px solid #0092D0;color: #662D91; padding-top:0px;}
  .blankTwo tr:nth-of-type(7n) td:nth-child(n+2){ background-color: #E6E8FF;opacity: .7;border-right: 1px solid #F4F4F4; }
  .blankTwo tr:nth-of-type(7n) td:last-child{ background-color: #E6E8FF;opacity: .7;border-right: 2px solid #0092D0; }

  .blankTwo td:first-child {    
    font-size: 25px;
    padding-bottom:0px;
    column-width: auto;
    border-left: 2px solid #0092D0;
    border-bottom: 1px solid #F4F4F4;
    padding-bottom:2px; padding-right:2px;padding-left:2px; vertical-align: bottom;line-height: 1.1;padding-top:2px;
}
.blankTwo td:last-child{
    border-right: 2px solid #0092D0;
    padding-bottom: 0px;
    column-width: auto;
}

.blankTwo td:nth-child(n+3){
    width: 90px;
}

/* .specWidth td{
    width: 70px;
} */
.specWidth tr{
    max-width: 90px;
}
    .blankTwo th:first-child {
color: rgba(0,0,0,0);
font-size: 0px;
opacity: 0;
border-right: 0px solid  #F4F4F4;
border-left: 2px solid  #B9B9B9;
    }
    .blankTwo th:last-child {
        border-right: 2px solid  #B9B9B9;
            }
            .blankTwo th:nth-child(n-1) {
                border-top: 2px solid  #B9B9B9;
                    }

    .blankTwo th:nth-child(2) {
        border-left: 0px solid #F4F4F4;
    }
    .blankTwo tr:last-child {
border-bottom: 2px solid #0092D0;
padding-bottom:0px;
    }
    /* .blankTwo th:first-child th:last-child{
        border-right: 2px solid  #B9B9B9;
        border-left: 2px solid  #B9B9B9;
            } */
 
    .blankTwoB tr {border: "1px solid black"; padding-bottom: 0px;border-left: 1px solid #0092D0;border-right: 1px solid #0092D0;}
    .blankTwoB td:nth-child(even) {background-color: #FFFFFF; border: "1px solid black"; padding-bottom:2px; padding-right:2px;padding-left:4px; vertical-align: bottom;line-height: 1.1;}
    .blankTwoB td:nth-child(odd) {background-color: #F4F4F4; border: "1px solid black";padding-bottom:2px; padding-right:2px;padding-left:4px; vertical-align: bottom;line-height: 1.1; }
    .blankTwoB td:first-child {color: #F4F4F4;}
    .blankTwoB tr:nth-of-type(5n+1) td  {
      color: #FFFFFF;
      font-size:25px;
      background-color: #0092D0;
      border: 1px solid #0092D0;
      border-top: 1px solid #F4F4F4;
      padding-top: 10px;
      padding-bottom:0px;
      padding-left:6px;
      padding-right:6px;
    }
    .blankTwoB tr:nth-of-type(5n+1) {border-left: 2px solid #0092D0;border-right: 2px solid #0092D0;}
    .blankTwoB tr:nth-of-type(5n) {font-weight: 700;border-bottom: 1px solid #0092D0;font-size:16px;}
  
.specTD th,td:last-child{
    width: 550px;
}

    .blankTwoB td:first-child {    
      font-size: 25px;
      padding-bottom:0px;
      column-width: auto;
      border-left: 2px solid #0092D0;
      border-bottom: 1px solid #F4F4F4;
      padding-bottom:2px; padding-right:2px;padding-left:2px; vertical-align: bottom;line-height: 1.1;padding-top:2px;
  }
  .blankTwoB td:last-child{
      border-right: 2px solid #0092D0;
      padding-bottom: 0px;
  }
      .blankTwoB th:first-child {
  color: rgba(0,0,0,0);
  font-size: 0px;
  opacity: 0;
  border-right: 0px solid;
      }
      .blankTwoB th:nth-child(2) {
          border-left: 0px solid;
      }
      .blankTwoB tr:last-child{
  border-bottom: 1px solid #0092D0;
  padding-bottom:0px;
      }


      .twoArm_tableBspec tr:nth-child(odd) {
        background-color: #F4F4F4;
        /* border-top: 2px solid black; */
    }
    .twoArm_tableBspec tr:nth-child(even) {
        background-color: #FFFFFF; 
        border-bottom: 2px solid #8C8785;
    }
    /* .twoArm_tableBspec td:last-child {
        border-right: 4px solid #D6D6D6;
    }
    .twoArm_tableBspec td:first-child {
        border-left: 4px solid #D6D6D6;
    } */

 page_{
    page-break-after: always;
    break-after: always;
}
     
   Container {
    page-break-inside: avoid;
  }

  .tooltipSCX {
    position: relative;
    display: inline-block;
    /* padding-left: 2px; */
    /* padding: 0px; */
    border-bottom: 1px dotted #005176;
  }
  
  .tooltipSCX .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #8C8785;
    color: #FFFFFF;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltipSCX:hover .tooltiptext {
    visibility: visible;
  }

  
/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip-own {
	position: absolute;
	padding: 0px !important;
	padding-top: 3px;
	font-weight:400 !important;
	font-size: 10px !important;
	/* background-color: #fff; */
	background-color: rgba(255,255,255,0) !important;
	/* border: 1px solid #fff; */
	border: 0px solid rgba(255,255,255,0) !important;
	border-radius: 0px;
	color: #000000;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	box-shadow: 0 0px 0px rgba(0,0,0,0)!important;
	}
  .leaflet-tooltip-own-top {
    position: absolute;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 5px !important;
    font-weight:400 !important;
    font-size: 10px !important;
    /* background-color: #fff; */
    background-color: rgba(255,255,255,0) !important;
    /* border: 1px solid #fff; */
    border: 0px solid rgba(255,255,255,0) !important;
    border-radius: 0px;
    color: #000000;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 0px 0px rgba(0,0,0,0)!important;
    }

  .leaflet-tooltip-own-houston {
    position: absolute;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 12px !important;
    text-align: left;
    font-weight:400 !important;
    font-size: 10px !important;
    /* background-color: #fff; */
    background-color: rgba(255,255,255,0) !important;
    /* border: 1px solid #fff; */
    border: 0px solid rgba(255,255,255,0) !important;
    border-radius: 0px;
    color: #C6C6C6 !important;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 0px 0px rgba(0,0,0,0)!important;
    }

    
	.leaflet-tooltip-own-tip {
		width: 0px!important;
		height: 0px !important;
		padding: 0px !important;
	}

.leaflet-tooltip.leaflet-interactive {
	cursor: pointer;
	pointer-events: auto;
	}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	position: absolute;
	pointer-events: none;
	border: 0px solid transparent !important;
	background: transparent !important;
	content: "" !important;
	}
  /* .leaflet-tooltip-own-top {
    margin-top: 0px !important;
    padding: 0px;
    padding-bottom: 5px;
  } */
/* Directions */

.leaflet-tooltip-bottom {
	margin-top: 0px !important;
  padding-top: 3px !important;
}

.leaflet-tooltip-top {
	margin-top: 0px !important;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
	left: 50%;
	margin-left: -6px !important;
	}
.leaflet-tooltip-top:before {
	bottom: 0;
	margin-bottom: -12px;
	border-top-color: rgba(255,255,255,0) !important;
	}
.leaflet-tooltip-bottom:before {
	top: 0;
	margin-top: 0px;
	margin-left: -6px;
	border-bottom-color: rgba(255,255,255,0) !important;
	}
.leaflet-tooltip-left {
	margin-left: -6px !important; 
}
.leaflet-tooltip-right {
	margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	top: 50%;
	margin-top: -6px !important;
	}
.leaflet-tooltip-left:before {
	right: 0;
	margin-right: -12px;
	border-left-color: rgba(255,255,255,0) !important;
	}
.leaflet-tooltip-right:before {
	left: 0;
	margin-left: -12px;
	border-right-color: rgba(255,255,255,0)!important;
	}

  .specialXXX table tr td {  
    background-color:#FFFFFF ;
    border: 1px solid  #B9B9B9;
  }

  .specialXXX th  {
    background-color: rgb(211,221,136,.7);
    color: #226277;
    border: 1px solid  #B9B9B9;
}

  .specialXXX th:first-child  {
      background-color: #226277;
      color: #D3DD88;
      border: 1px solid  #B9B9B9;
   }
  
   .specialXXX th:nth-child(2)  {
      background-color: #D3DD88;
      color: #226277;
      border: 1px solid  #B9B9B9;
  }

  .specialXSCD2YA table {  background-color:#FFFFFF ;}
  .specialXSCD2YA td {
    padding: 4px 5px;
    z-index: 0;
  }
  
  .specialXSCD2YA tbody  {
    position: relative;
    z-index: 0;
  }
  .specialXSCD2YA tbody tr {
    background-color:#FFFFFF ;
    z-index: 0;
  }

  .specialXSCD2YA thead th {
    padding: 0px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    max-width: 80px;
    /* background: white; */
  }
  .specialXSCD2YA tr th:first-child {
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
  }

  .specialXSCD2YA thead th:nth-child(even) {
    position: sticky;
    left: 0;
    top: 0;
    /* z-index: 2; */
    /* width: 25vw; */
    background-color: #226277;
    color: #D3DD88;
  }
  .specialXSCD2YA thead th:nth-child(odd) {
    position: sticky;
    left: 0;
    top: 0;
    /* z-index: 2; */
    /* width: 25vw; */
    color: #226277;
    background-color: #D3DD88;
  }

  /* td:nth-child(even) */
  .specialXSCD2YA thead th:first-child {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 40;
    width: 25vw;
    background-color:  #000000;
    color: #FFFFFF;
  }

  .specialXSCD2YA tr td:first-child {
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
  }
  
  .specialXSCD2YA tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  .specialXSCD200x th:nth-child(odd) {
    background-color: #BCBD22;
    padding: 0px;
}
    .specialXSCD200x th:nth-child(even) {
        background-color: #226277;
        padding: 0px;
    }
    .specialXSCD200x thead th {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 2;
      /* width: 100vw; */
      background-color:  #000000;
      /* max-width: 8%; */
      max-width: 20px;
    }
  .specialXSCD200x thead th {
    padding: 0px;
    position: sticky;
    top: 0;
    z-index: 1;
    /* width: 100vw; */
    width: 35%;
    /* max-width: auto; */
    background-color:  #000000;
    /* max-width: 80px; */
    /* background: white; */
  }
  .specialXSCD200x tr th:first-child {
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color:  #000000;
    width: 35%;
  }

  .specialXSCD200x thead th:first-child {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
    /* width: 25vw; */
    width: 35%;
    background-color:  #000000;
  }
  /* .specialXSCD200x thead th:nth-child(2) {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
    /* width: 25vw; */
    /* width: 250px; */
    /* background-color:  #000000; */
  
    .specialXSCD200x tr th:first-child {
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
    width: 35%;
  }

  .specialXSCD200x thead th:first-child {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
    /* width: 25vw; */
    background-color:  #000000;
    width: 35%;
  }

  .specialXSCD200x tr td:first-child {
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
    width: 35%;
  }
  
  .specialXSCD200x tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    width: 35%;
    /* width: "20%"; */
  }
  
  .specialStickyRtm table {  background-color:#FFFFFF ;}
  .specialStickyRtm td {
    padding: 4px 5px;
    z-index: 0;
  }
  
  .specialStickyRtm tbody  {
    position: relative;
    z-index: 0;
  }
  .specialStickyRtm tbody tr {
    background-color:#FFFFFF ;
    z-index: 0;
  }

  .specialStickyRtm thead th {
    padding: 0px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    /* background: white; */
  }
  .specialStickyRtm tr td:first-child {
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
  }

  .specialStickyRtm thead tr:first-child {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 40;
    width: 25vw;
    /* background-color:  #000000; */
  }
 
  .specialStickyRtm tr th:first-child {
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
  }
  
  .specialStickyRtm tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
.specialStickyRtm tbody td:nth-child(odd){
  width: 4px;
  max-width: 4px;
  /* min-width: 4px; */
}
.specialStickyRtm thead th:nth-child(even){
  width: 75px;
  max-width: 75px;
  min-width: 60px;

}


.specialStickyRFML table {  background-color:#FFFFFF ;}
    .specialStickyRFML td {
      padding: 4px 5px;
      z-index: 0;
      	/* min-width: 40px; */
		min-width: 80px;
    }
    
    /* .specialStickyRFML tbody  {
      position: relative;
      z-index: 0;
    } */
    .specialStickyRFML tbody tr {
      background-color:#FFFFFF ;
      z-index: 0;
    }
  
    .specialStickyRFML thead th {
      padding: 0px;
      position: sticky;
      top: 0;
      z-index: 1;
      width: 25vw;
      	/* min-width: 40px; */
		min-width: 80px;
    /* max-width: 90px; */
      /* background: white; */
    }
    .specialStickyRFML tr td:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
      min-width: 90px;

    }
  
    .specialStickyRFML thead tr:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 40;
      width: 25vw;
      line-height: 1;
      /* background-color:  #000000; */
    }
   
    .specialStickyRFML tr th:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
    }
    
    .specialStickyRFML th {
	/* min-width: 40px; */
		min-width: 80px;
    }
    .specialStickyRFML tbody th:last-child {
      width: auto;
      min-width: 100px;
     }

    .specialStickyRFML tbody td {
      width: 100px;
     }
    .specialStickyRFML tbody td:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }

    .specialStickyRFML tbody td:last-child {
     width: auto;
     min-width: 100px;
    }

 
.specialStickyRFMLSX table {  background-color:#FFFFFF ;}
.specialStickyRFMLSX td {
  padding: 4px 5px;
  z-index: 0;

}


.specialStickyRFMLSX tbody tr {
  background-color:#FFFFFF ;
  z-index: 0;
}

.specialStickyRFMLSX thead th {
  padding: 0px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
}
.specialStickyRFMLSX tr td:first-child {
  position: sticky;
  left: 0px;
  z-index: 40;
  background-color: inherit;
/*       min-width: 90px; */

}

.specialStickyRFMLSX thead tr:first-child {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 40;
  width: 25vw;
  line-height: 1;
  /* background-color:  #000000; */
}

.specialStickyRFMLSX tr th:first-child {
  position: sticky;
  left: 0px;
  z-index: 40;
  background-color: inherit;
}


/* .specialStickyRFMLSX tbody th:last-child {
  width: auto;
 } */

/* .specialStickyRFMLSX tbody td {
  width: 100px;
 } */
.specialStickyRFMLSX tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

.specialStickyRFMLSX  th{
  max-width: 50px;
}
.specialStickyRFMLSX  td{
  max-width: 50px;
}
.specialStickyRFMLSX  th:nth-child(3){
  max-width: 90px;
}    
.specialStickyRFMLSX  th:nth-child(4){
  max-width: 80px;
}

    .specialStickyRFMLSS tbody th:last-child {
      width: auto;
      min-width: 140px;
     }

    .specialStickyRKMN table {  background-color:#FFFFFF ;}
    .specialStickyRKMN td {
      padding: 4px 5px;
      z-index: 0;
      	min-width: 40px;
		/* min-width: 80px; */
    }
    
    .specialStickyRKMN tbody  {
      position: relative;
      z-index: 0;
    }
    .specialStickyRKMN tbody tr {
      background-color:#FFFFFF ;
      z-index: 0;
    }
  

    .specialStickyRKMN thead th:first-child {
      padding: 0px;
      position: sticky;
      top: 0;
      z-index: 1;
      width: 25vw;
      	min-width: 40px;
		/* max-width: 80px; */
      /* background: white; */
    }
    .specialStickyRKMN thead th:not(:first-child) {
      padding: 0px;
      position: sticky;
      top: 0;
      z-index: 1;
      width: 25vw;
      	max-width: 65px;
		/* min-width: 35px; */
      /* background: white; */
    }
    .specialStickyRKMN tr td:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
    }
  
    .specialStickyRKMN thead tr:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 40;
      width: 25vw;
      /* background-color:  #000000; */
    }
   
    .specialStickyRKMN tr th:first-child {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
    }
    .specialStickyRKMN tr td:not(:first-child) {
      position: sticky;
      left: 0px;
      z-index: 40;
      background-color: inherit;
      max-width: 50px;
    }
/*     
    .specialStickyRKMN th {
	min-width: 40px;
		max-width: 80px;
    } */

    .specialStickyRKMN tbody td:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
      /* max-width: 80px; */
    }

    .keY table {
      padding: 0px;
      border: 1px solid  #B9B9B9;
       }
       .keY tr {
       border: double #941751;
       /* padding-top: 2px; */
       /* padding-bottom: 2px; */
       }
       
      .keY tr:nth-child(odd) td:first-child {
          background-color: #FFFFFF;
          width: 25px;
          border: double #941751;
        padding-right:5px;
        padding-left: 4px;
         font-size: 12px;
         padding-top: 4px;
         padding-bottom: 4px;
        }
        .keY tr:nth-child(odd) td:nth-child(2) {
          width: 290px;
          background-color: #F4F4F4;
          border: double #941751;
        padding-right:5px;
        padding-left: 4px;
         font-size: 12px;
         padding-top: 4px;
         padding-bottom: 4px;
        }
        .keY tr:nth-child(even)  td:first-child{
          width: 25px;
          background-color: #F4F4F4;
          border: double #941751;
        padding-right:5px;
        padding-left: 4px;
         font-size: 12px;
         padding-top: 4px;
         padding-bottom: 4px;
        }
       .keY tr:nth-child(even) td:nth-child(2) {
        width: 290px;
          background-color: #FFFFFF;
          border: double #941751;
        padding-right:5px;
        padding-left: 4px;
         font-size: 12px;
         padding-top: 4px;
         padding-bottom: 4px;
        }

        .keYflip table {
          padding: 0px;
          border: 1px solid  #B9B9B9;
           }
           .keY tr {
           border: double #941751;
           /* padding-top: 2px; */
           /* padding-bottom: 2px; */
           }
           
          .keYflip tr:nth-child(odd) td:first-child {
              background-color: #FFFFFF;
              width: 25px;
              border: double #941751;
            padding-right:5px;
            padding-left: 4px;
             font-size: 12px;
             padding-top: 4px;
             padding-bottom: 4px;
            }
            .keYflip tr:nth-child(odd) td:nth-child(2) {
              width: 290px;
              background-color: #F4F4F4;
              border: double #941751;
            padding-right:5px;
            padding-left: 4px;
             font-size: 12px;
             padding-top: 4px;
             padding-bottom: 4px;
            }
            .keYflip tr:nth-child(even)  td:first-child{
              /* width: 25px; */
              background-color: #F4F4F4;
              border: double #941751;
            padding-right:5px;
            padding-left: 4px;
             font-size: 12px;
             padding-top: 4px;
             padding-bottom: 4px;
            }
           .keYflip tr:nth-child(even) td:nth-child(2) {
            /* width: 290px; */
              background-color: #FFFFFF;
              border: double #941751;
            padding-right:5px;
            padding-left: 4px;
             font-size: 12px;
             padding-top: 4px;
             padding-bottom: 4px;
            }